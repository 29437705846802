import Base from './base'

export default class Registration extends Base {
  static get url () {
    return this.apiUrl
  }

  static async getProfile () {
    const { data } = await this._get(`${this.apiUrl}/registrations`)
    return data.data
  }
}
