<template>
  <v-chip :color="statusColor" :label="true" small v-bind="$props">
    {{ $t(status.toUpperCase()) }}
  </v-chip>
</template>

<script>
export default {
  name: 'StatusChipComp',

  props: {
    status: {
      type: String,
      required: true
    },
    outline: {},
    dark: {
      default: true
    },
    light: {}
  },

  data () {
    return {
    }
  },

  components: {
  },

  computed: {
    statusColor () {
      switch (this.status) {
        case 'pending':
          return 'red'
        case 'active':
          return 'success'
        case 'suspended':
          return 'red'
        default:
          return ''
      }
    }
  },

  methods: {
  },

  watch: {
  },

  async mounted () {
  }
}
</script>
