// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'
// import { getTheme } from '@/utils'
import { useI18n } from 'vue-i18n'

// Vuetify
import { createVuetify } from 'vuetify'
import { createVueI18nAdapter } from 'vuetify/locale/adapters/vue-i18n'

// import colors from 'vuetify/lib/util/colors'

import { aliases, fa } from 'vuetify/iconsets/fa-svg'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import '@/stylesheets/app.scss'

library.add(fas)
library.add(far)

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default function (i18n) {
  return createVuetify({
    locale: {
      adapter: createVueI18nAdapter({ i18n, useI18n }),
    },
    icons: {
      defaultSet: 'fa',
      aliases,
      sets: {
        fa,
      },
    },
    // theme: {
    //   defaultTheme: getTheme(),
    //   themes: {
    //     light: {
    //       colors: {
    //         primary: process.env.VUE_APP_PRIMARY_COLOR,
    //         'secondary-bg': colors.grey.lighten4,
    //         // background: '#000000',
    //       }
    //     },
    //     dark: {
    //       colors: {
    //         primary: process.env.VUE_APP_PRIMARY_COLOR,
    //         'secondary-bg': colors.grey.darken4,
    //         // background: '#000000',
    //       }
    //     },
    //   }
    // },
  })
}

