import Base from './base'

export default class Session extends Base {
  static get url () {
    return this.apiUrl
  }

  static async signin (params) {
    const { data } = await this._post(`${this.apiUrl}/sign_in`, params)
    return data
  }

  static signout () {
    // TODO: delete token 
  }
}
