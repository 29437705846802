<template>
  <div class="stepper-box">
    <div class="top">
      <div class="divider-line" :style="{width: `${(100/(steps.length) * (steps.length - 1)) - 10}%`}"></div>
      <div class="steps-wrapper">
        <template v-for="(step, index) in steps" :key="index">
          <div :class="['step', isCurrentStep(step)]" :style="{width: `${100 / steps.length}%`}">
            <div class="circle">
              <v-icon :icon="`fas- fa-${step.icon}`" size="xsmall"></v-icon>
            </div>
            <div class="step-title">
              <h4>{{ step.title }}</h4>
              <h5 class="step-subtitle" v-if="step.subtitle">{{ step.subtitle }}</h5>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
// https://github.com/PygmySlowLoris/vue-stepper
export default {
  name: "StepperComp",

  data () {
    return {
    }
  },

  props: {
    steps: {
      required: true,
      type: Array
    },

    currentStep: {
      required: true,
      type: Number,
    },
  },

  components: {
  },

  computed: {
  },

  methods: {
    isCurrentStep (step) {
      if (step.index !== this.currentStep) {
        return 'deactivated'
      }

      return ''
    },
  },

  watch: {
  },

  mounted () {
  }
}
</script>

<style lang="scss">
@mixin shadow($level: 1) {
  @if $level==1 {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  }
  @else if $level==2 {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  @else if $level==3 {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  @else if $level==4 {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
  @else if $level==5 {
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
  }
}

@mixin breakpoint($class) {
  @if $class==xs {
    @media (max-width: 767px) {
      @content;
    }
  }
  @else if $class==sm {
    @media (min-width: 768px) {
      @content;
    }
  }
  @else if $class==md {
    @media (min-width: 992px) {
      @content;
    }
  }
  @else if $class==lg {
    @media (min-width: 1200px) {
      @content;
    }
  }
  @else {
    @warn "Breakpoint mixin supports: xs, sm, md, lg";
  }
}

.stepper-box {
  background-color: white;
  //@include shadow(1);
  min-height: 100px;
  .top {
    .stepper-button-top {
      z-index: 20;
      padding: .4rem;
      border-radius: 100rem;
      cursor: pointer;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.next {
        border: 2px solid #3383c8;
        color: #3383c8;
        right: 1%;
        &.deactivated {
          border: 2px solid #cccccc !important;
          color: #cccccc;
          cursor: not-allowed !important;
        }
      }
      &.previous {
        color: #333333;
        left: 1%;
      }
    }
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    .divider-line {
      border-bottom: 1px solid #cccccc;
      height: 2px;
      position: absolute;
      @include breakpoint(xs) {
        width: 90%;
      }
    }
    .steps-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      width: 95%;
      left: 0;
      padding: 2% 4%;
      @include breakpoint(xs) {
        width: 90%;
        justify-content: center;
      }
      .step {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        @include breakpoint(xs) {
          width: 100% !important;
        }
        &.deactivated {
          @include breakpoint(xs) {
            display: none;
          }
          .circle {
            i {
              background-color: #bbbbbb !important;
            }
          }
          .step-title {
            opacity: .35;
          }
        }
        .circle {
          margin-bottom: 1rem;
          padding: 0 1rem;
          background-color: white;
          i {
            background-color: #3383c8;
            color: #fff;
            border-radius: 100rem;
            padding: 1rem;
          }
        }
        .step-title {
          position: absolute;
          top: 90%;
          width: 100%;
          h1,
          h2,
          h3,
          h4,
          h5 {
            margin: 0 0 .2rem 0;
            color: #333333;
            font-weight: bold;
          }
          .step-subtitle {
            font-weight: lighter;
            margin: 0;
            color: #555555;
          }
        }
      }
    }
  }
  .content {
    overflow: hidden;
    margin: 1.5rem 0;
  }
  .bottom {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    border-top: 1px solid #cccccc;
    &.only-next {
      justify-content: flex-end;
    }
    .stepper-button {
      padding: .5rem 1rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.next {
        background-color: #3383c8;
        color: white;
        @include shadow(1);
        &.deactivated {
          background-color: #cccccc !important;
          cursor: not-allowed !important;
        }
      }
      &.previous {
        color: #333333;
      }
    }
  }
}
</style>
