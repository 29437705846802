<template>
  <v-btn icon density="compact" @click="toggleSwitch">
    <v-icon :color="value ? 'red' : 'green'" size="xsmall">
      {{ value ? "fas fa-cancel" : "fas fa-check" }}
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "SwitchStatusBtnComp",
  props: {
    modelValue: {
      type: String,
      validator: (value) => {
        return ["active", "suspended"].includes(value);
      },
    },
  },
  emits: ["update:modelValue", "click"],
  computed: {
    value: {
      get() {
        return this.modelValue === "active";
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    toggleSwitch() {
      this.switchOn = !this.switchOn;
      const newValue = this.switchOn ? "active" : "suspended";
      this.$emit("input", newValue);
      this.$emit("click", newValue);
    },
  },
};
</script>
