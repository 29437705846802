import Session from '@/clients/session'
import { getAuth, setAuth, deleteAuth } from '@/utils'

const auth = getAuth()

const mutations = {
  setCurrentUser (state, obj) {
    state.current = {
      isCustomer: obj.user_type === 'customer',
      isAdmin: obj.user_type === 'admin',
      isMainAdmin: obj.user_type === 'main_admin',
      ...obj
    }
  }
}

const getters = {
  currentUser (state) {
    return state.current
  },

  isAuthenticated (state) {
    return !!state.current.token
  }
}

const actions = {
  setCurrentUser ({ commit }, user) {
    commit('setCurrentUser', Object.assign({}, user, { token: auth.token }))
  },

  async login ({ commit }, credentials) {
    const data = await Session.signin(credentials)
    setAuth(data.data)

    commit('setCurrentUser', data.data)
  },

  async logout ({ commit }) {
    Session.signout()
    deleteAuth()
    commit('setCurrentUser', getAuth())
  }
}

export default {
  state: {
    current: {
      isCustomer: auth.user_type === 'customer',
      isAdmin: auth.user_type === 'admin',
      isMainAdmin: auth.user_type === 'main_admin',
      ...auth
    }
  },
  actions,
  getters,
  mutations
}
