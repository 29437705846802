import {
  snakeCase,
  sortBy,
  orderBy,
  uniqBy,
  get
} from 'lodash'
import Package from '../package.json'
import { mapGetters, mapActions } from 'vuex'
import { ErrorBag } from 'vee-validate';
import Registration from '@/clients/registration'

export default {
  data () {
    return {
      logoUrl: process.env.VUE_APP_LOGO_URL,
      whiteLogoUrl: process.env.VUE_APP_WHITE_LOGO_URL,
      faviconUrl: process.env.VUE_APP_FAVICON_URL,
      tenantName: process.env.VUE_APP_TENANT_NAME,
      appVersion: Package.version,
      appColor: process.env.VUE_APP_COLOR,
      errors: new ErrorBag(),
      validations: {
        required: value => !!value || this.$t('REQUIRED'),
        counter10: value => value.length <= 10 || this.$t('MAX_CHARS', [10]),
        counter35: value => value.length <= 35 || this.$t('MAX_CHARS', [35]),
        greaterThan0: value => Number(value) > 0 || this.$t('GREATER_THAN', [0]),
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || this.$t('INVALID_EMAIL')
        },
      },
    }
  },

  computed: {
    ...mapGetters([
      'isAuthenticated',
      'currentUser'
    ])
  },

  methods: {
    ...mapActions([
      'setCurrentUser',
      'setNotification',
      'clearNotification',
      'logout'
    ]),
    
    async fetchCurrentUser () {
      const profile = await Registration.getProfile()
      this.setCurrentUser(profile)
    },

    snakeCase,

    sort (arr, field = 'name') { return sortBy(arr, field) },

    orderBy (arr, field = 'name', sort = 'asc') { return orderBy(arr, field, sort) },

    uniqBy (arr, field = 'name') { return uniqBy(arr, field) },

    get (object, path, defaultValue) { return get(object, path, defaultValue) },

    goBack () { this.$router.go(-1) },

    goTo (route, params, query) {
      if (typeof params === 'string' || params instanceof String) { params = { id: params } }
      this.$router.push({ name: route, params, query })
    },

    handleErr (err) {
      if (!err.response) {
        return this.setNotification({ type: 'error', message: err.message })
      }

      // If user is not logged in or ivalid token
      if (err.response.status === 401) {
        this.logout()
        return this.goTo('home')
      }

      const data = err.response.data
      if (!data) {
        return this.setNotification({ type: 'error', message: err.message })
      }
      const msgs = data.messages
      if (!msgs) {
        return this.setNotification({ type: 'error', message: err.message })
      }

      if (Array.isArray(msgs)) {
        this.setNotification({ type: 'error', message: msgs.join('<br/>') })
      } else {
        this.setNotification({ type: 'error', message: msgs })
      }
    },

    messagesFromErr (err) {
      const message = get(err, 'response.data.error.message')
      if (message) { return [message] }

      const messages = get(err, 'response.data.error.details.messages')
      if (messages) {
        return Object.keys(messages).map(key => {
          return `${key} ${messages[key][0]}`
        })
      }

      return [err.message]
    }
  },

  created() {
  }
}
