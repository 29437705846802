<template>
  <div class="notification">
    <v-snackbar v-model="notification.isVisible" multi-line :color="notification.type">
      {{ notification.message }}
      <template v-slot:actions>
        <v-btn variant="text" @click="hide">
          {{ $t('CLOSE') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "NotificationComp",

  props: {
  },

  components: {
  },

  computed: {
    ...mapGetters([
      'notification'
    ])

  },

  methods: {
    hide () {
      this.notification.isVisible = false
    }
  },

  watch: {
    // notification () {
    //   console.log(this.notification)
    // }
  },

  mounted () {
  }
}
</script>
