<template>
  <v-btn
    icon="fas fa-pen-to-square"
    variant="text"
    size="small"
    @click="goTo(routeName, routeParams)"
  ></v-btn>
</template>

<script>
export default {
  name: "EditBtnComp",

  data () {
    return {
    }
  },

  props: {
    routeName: {
      required: true,
      type: String
    },
    routeParams: {
      required: true,
      type: Object
    },
  },

  components: {
  },

  computed: {
  },

  methods: {
  },

  watch: {
  },

  mounted () {
  }
}
</script>
