<template>
  <v-btn icon density="compact" @click="editItem">
    <v-icon icon="fas fa-edit" size="sm" color="primary"></v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "EditButton",
  methods: {
    editItem() {
      // Call the editItem method from the parent component
      this.$emit("click");
    },
  },
};
</script>