<template>
  <v-app>
    <AppBar v-if="isAuthenticated"/>

    <v-main>
      <router-view/>
    </v-main>

    <Notification></Notification>
  </v-app>
</template>

<script>
import AppBar from '@/components/AppBar'
import Notification from '@/components/shared/NotificationComp'

export default {
  name: 'App',

  data: () => ({
  }),

  components:{
    AppBar,
    Notification
  },

  mounted () {
  }
}
</script>
