import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { loadFonts } from './plugins/webfontloader'
import i18n from './plugins/i18n'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'

import globalMixin from '@/mixin'
import { register } from '@/shared-components'
import filters from '@/filters'

loadFonts()

const app = createApp(App)
app.mixin(globalMixin)
app.use(i18n)
app.use(router)
app.use(store)
app.use(vuetify(i18n))
app.component('font-awesome-icon', FontAwesomeIcon)
library.add(fas) // Include needed solid icons
library.add(far) // Include needed regular icons

register(app)
filters(app)
app.mount('#app')
