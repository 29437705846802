import axios from 'axios'
import { getAuth, getTenant } from '@/utils'

export default class Base {
  static get url () {
    return this.apiUrl
  }

  static get apiUrl () {
    return process.env.VUE_APP_API_URL
  }

  static get headers () {
    return {
      'X-TENANT-ID': getTenant().id,
      'X-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
      Authorization: `Token token=${getAuth().token}`
    }
  }

  static get defaultAttributes () {
    return {}
  }

  static get responseRootKey () {
    return 'data'
  }

  static async all (filter) {
    let url = this.url
    const { data } = await this._get(url, filter)
    return this._mapCollection(data)
  }

  static async find (id, filter = {}) {
    let url = this.url
    const { data } = await this._get(`${url}/${id}`, filter)

    if (this.responseRootKey) {
      return new this(data[this.responseRootKey])
    }

    return new this(data)
  }

  static async create (payload) {
    const record =  new this(payload)
    await record.create()
    return record
  }

  static async count (filter) {
    let url = this.url
    const res = await this._get(url + '/count', filter)

    if (this.responseRootKey) {
      return res[this.responseRootKey]
    }
    return res
  }

  static get http () {
    return axios
  }

  static async _get (url, params) {
    if (!this.http) { throw new Error('MSModel HTTP Client has not been setup yet!') }

    return this.http.get(url, { headers: this.headers, params })
  }

  static async _post (url, params) {
    if (!this.http) { throw new Error('MSModel HTTP Client has not been setup yet!') }

    return this.http.post(url, params, { headers: this.headers })
  }

  static async _patch (url, params) {
    if (!this.http) { throw new Error('MSModel HTTP Client has not been setup yet!') }

    return this.http.patch(url, params, { headers: this.headers })
  }

  static async _put (url, params) {
    if (!this.http) { throw new Error('MSModel HTTP Client has not been setup yet!') }

    return this.http.put(url, params, { headers: this.headers })
  }

  static async _delete (url) {
    if (!this.http) { throw new Error('MSModel HTTP Client has not been setup yet!') }

    return this.http.delete(url, { headers: this.headers })
  }

  static _mapCollection (response) {
    let records = response
    if (this.responseRootKey) {
      records = response[this.responseRootKey]
    }

    const collection = records.map(record => {
      return new this(record)
    })

    if (response.meta) {
      collection.meta = response.meta
    }
    return collection
  }

  static mapRecord (response, record) {
    return record
  }

  // Prototype -------------------------------------------------------------------------------------

  constructor (attrs = {}) {
    this._setAttributes(Object.assign(this.constructor.defaultAttributes, attrs))
  }

  _setAttributes (attrs) {
    if (!attrs) { return }

    Object.defineProperty(this, 'oldValues', {
      value: attrs,
      enumerable: false,
      writable: false,
      configurable: true
    })

    Object.keys(attrs).forEach(key => {
      this._setAttribute(key, attrs[key])
    })
  }

  _setAttribute (key, value) {
    this[key] = value
    Object.defineProperty(this, key, {
      value: value,
      enumerable: true,
      writable: true
    })
  }

  async save () {
    if (this.id) {
      return this.update()
    } else {
      return this.create()
    }
  }

  get nestedColumn () {
    let value
    if (this.oldValues) {
      value = this.oldValues[`${this.constructor.nestedResource}Id`] ||
        this.oldValues[`${this.constructor.nestedResource}_id`]
    }
    if (value) { return value }

    return this[`${this.constructor.nestedResource}Id`] ||
      this[`${this.constructor.nestedResource}_id`]
  }

  get resourceRoute () {
    return this.constructor.url.replace(this.constructor.apiUrl, '')
  }

  async create () {
    let payload = {}
    payload = { ...this }
    let url = this.constructor.url
    const { data } = await this.constructor._post(url, payload)

    if (this.constructor.responseRootKey) {
      this._setAttributes(data[this.constructor.responseRootKey])
    } else {
      this._setAttributes(data)
    }

    return true
  }

  async update () {
    let payload = {}
    payload = this.attributesToUpdate()
    if (!Object.keys(payload).length) { return true }

    let url = this.constructor.url
    let res
    if (this.constructor.updateWithPut) {
      res = await this.constructor._put(`${url}/${this.id}`, payload)
    } else {
      res = await this.constructor._patch(`${url}/${this.id}`, payload)
    }
    if (this.constructor.responseRootKey) {
      this._setAttributes(res.data[this.constructor.responseRootKey])
    } else {
      this._setAttributes(res.data)
    }

    return true
  }

  async destroy () {
    const url = this.constructor.url
    await this.constructor._delete(`${url}/${this.id}`)

    return true
  }

  attributesToUpdate () {
    if (this.constructor.sendAllAttributesOnUpdate) {
      return { ...this }
    }

    const attrs = {}
    const keys = Object.keys(this)
    for (let key of keys) {
      if (this[key] !== this.oldValues[key]) {
        attrs[key] = this[key]
      }
    }
    return attrs
  }
}
