import { createI18n } from 'vue-i18n'
import { getLocale } from '@/utils'
import { en, es } from 'vuetify/lib/locale'

export default createI18n({
  locale: getLocale(),
  fallbackLocale: 'es-MX',
  messages: {
    en: Object.assign(require('../locales/en.json'), { $vuetify: en }),
    'es-MX': Object.assign(require('../locales/es-MX.json'), { $vuetify: es }),
  },
})
