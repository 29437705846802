<style scoped>
.v-btn {
  margin-left: -5px;
  margin-top: -4px;
}
</style>
<template>
  <v-card-title>
    <h3 v-if="title">
      <v-btn
        v-if="back"
        icon
        flat
        density="compact"
        class="v-btn"
        @click="goToBack"
      >
        <v-icon color="primary" size="xsm">fas fa-arrow-left</v-icon>
      </v-btn>
      {{ title }}
    </h3>
    <v-skeleton-loader
      max-width="300"
      type="heading"
      v-else
    ></v-skeleton-loader>
  </v-card-title>
</template>

<script>
export default {
  name: "CardTitleComp",

  props: {
    title: {
      type: String,
      default: null,
    },
    back: {
      type: Boolean,
      default: false,
    },
    route: {
      type: Object,
      default: null,
    },
  },

  methods: {
    goToBack() {
      if (this.route) {
        this.$router.push(this.route);
      } else {
        this.goBack();
      }
    },
  },
};
</script>