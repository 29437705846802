const mutations = {
  setNotification (state, notification) {
    state.current = {
      isVisible: true,
      ...notification
    }
  },

  clearNotification (state) {
    state.current = {}
  }
}

const getters = {
  notification (state) {
    return state.current
  }
}

const actions = {
  setNotification ({ commit }, notification) {
    commit('setNotification', notification)
  },

  clearNotification ({ commit }) {
    commit('clearNotification')
  }
}

export default {
  state: {
    current: {}
  },
  actions,
  getters,
  mutations
}
