import EditBtn from '@/components/shared/EditBtnComp.vue';
import Stepper from '@/components/shared/StepperComp.vue';
import StatusChip from '@/components/shared/StatusChipComp.vue';
import EditBtnDialog from '@/components/shared/EditBtnDialogComp.vue';
import SwitchStatusBtn from '@/components/shared/SwitchStatusBtnComp.vue';
import CardTitle from '@/components/shared/CardTitleComp.vue';

export function register(app) {
  app.component('EditBtn', EditBtn);
  app.component('HsStepper', Stepper);
  app.component('StatusChip', StatusChip);
  app.component('EditBtnDialog', EditBtnDialog);
  app.component('SwitchStatusBtn', SwitchStatusBtn);
  app.component('CardTitle', CardTitle);
}
