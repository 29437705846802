import { createStore } from 'vuex'

import Notification from './notification'
import Session from './session'

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Notification,
    Session
  }
})
