import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from '@/utils'

const routes = [
  { path: '/',       name: 'home',  component: () => import('@/views/HomeView.vue') },
  { path: '/about',  name: 'about', component: () => import('../views/AboutView.vue') },
  { path: '/login',  name: 'login', component: () => import('@/views/Session/SignInView.vue') },
  { path: '/logout', name: 'logout', meta: { requiresAuth: true } },

  { path: '/providers',          name: 'providers',    component: () => import('@/views/Providers/ListView.vue') },
  { path: '/providers/new',      name: 'newProvider',  component: () => import('@/views/Providers/FormView.vue') },
  { path: '/providers/:id/edit', name: 'editProvider', component: () => import('@/views/Providers/FormView.vue') },

  { path: '/tenants',          name: 'tenants',    component: () => import('@/views/Tenants/ListView.vue') },
  { path: '/tenants/:id/edit', name: 'editTenant', component: () => import('@/views/Tenants/FormView.vue'), },
  { 
    path: '/tenants/:id', 
    component: () => import('@/views/Tenants/DetailsView.vue'),
    children: [
      { path: 'settings', name: 'settings',      component: () => import('@/views/Settings/ListView.vue') },
      { path: 'carriers', name: 'carriers',      component: () => import('@/views/Carriers/ListView.vue') },
    ]  
  },
  { 
    path: '/carriers/:id', 
    component: () => import('@/views/Carriers/DetailsView.vue'),
    children: [
      { path: '',         name: 'services', component: () => import('@/views/Services/ListView.vue') },
      { path: 'services', name: 'services', component: () => import('@/views/Services/ListView.vue') },
    ]
  },
  // { path: '/shipments/new', name: 'newShipment', component: () => import('@/views/Shipments/CreateView.vue') },
  // { path: '/shipments/:shipment_id/parcels/new', name: 'newParcel', component: () => import('@/views/Parcels/CreateView.vue') },
  // { path: '/shipments/:shipment_id/rates', name: 'rates', component: () => import('@/views/Rates/ListView.vue') },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to) => {
  const auth = getAuth()
  const isAuthenticated = !!auth.token
  if (
    // make sure the user is authenticated
    !isAuthenticated &&
      // ❗️ Avoid an infinite redirect
      to.name !== 'login'
  ) {
    // redirect the user to the login page
    return { name: 'login' }
  }

  return true
})

export default router
