<template>
  <v-app-bar flat class="bg-secondary-bg">
    <v-container class="mx-auto d-flex align-center justify-center">
      <v-avatar class="me-4" color="grey-lighten-3" size="32">
        <v-img :src="logoUrl" alt="logo"></v-img>
      </v-avatar>

      <v-btn
        v-for="link in links"
        :key="link.name"
        :text="link.name"
        variant="text"
        color="primary"
        @click="goTo(link.route)"
      ></v-btn>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn
            v-bind="props"
            :text="$t('MORE')"
            variant="text"
            color="primary"
            class="text-normal"
          ></v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="menu in subMenu"
            :key="menu.name"
            :value="menu.route"
            @click="goTo(menu.route)"
          >
            <v-list-item-title>
              {{ menu.name }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn
        v-if="!currentUser.isCustomer"
        text="Admin"
        variant="text"
        color="primary"
        @click="goTo('adminPanel')"
      ></v-btn>

      <v-spacer></v-spacer>

      <!-- <v-responsive max-width="160"> -->
      <!--   <v-text-field -->
      <!--     density="compact" -->
      <!--     flat -->
      <!--     hide-details -->
      <!--     label="Search" -->
      <!--     rounded="lg" -->
      <!--     single-line -->
      <!--     variant="solo-filled" -->
      <!--   ></v-text-field> -->
      <!-- </v-responsive> -->

      <!-- <b>
        {{ $filters.currency(currentUser.balance) }}
      </b> -->

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props">
            <img :src="langs[$i18n.locale]" width="25" :alt="$i18n.locale" />
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="locale in $i18n.availableLocales"
            :key="`locale-${locale}`"
            :value="locale"
            @click="changeLocale(locale)"
          >
            <v-list-item-title>
              <img :src="langs[locale]" width="25" :alt="locale" />
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu>
        <template v-slot:activator="{ props }">
          <v-btn icon="fas fa-user" size="small" v-bind="props"></v-btn>
        </template>

        <v-list>
          <v-list-item @click="toggleTheme">
            <v-list-item-title>{{ currentTheme }}</v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="signout">
            <v-list-item-title>{{ $t("SIGN_OUT") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-container>
  </v-app-bar>
</template>

<script setup>
import { useTheme } from "vuetify";
import { setTheme } from "@/utils";

const theme = useTheme();

function toggleTheme() {
  const newTheme = theme.global.current.value.dark ? "light" : "dark";
  setTheme(newTheme);
  theme.global.name.value = newTheme;
}
</script>

<script>
import { setLocale } from "@/utils";

export default {
  name: "AppBar",

  data: () => ({
    logoUrl: require("@/assets/logo.png"),
    langs: {
      en: require("@/assets/lang/en-US.svg"),
      "es-MX": require("@/assets/lang/es-MX.svg"),
    },
  }),

  computed: {
    links() {
      return [
        { name: "Dashboard", route: "home" },
        { name: this.$tc("TENANT", 2), route: "tenants" },
        { name: this.$tc("PROVIDER", 2), route: "providers" },
      ];
    },

    subMenu() {
      return [
        { name: this.$tc("TRANSACTION", 2), route: "transactions" },
        { name: this.$tc("ADD_CREDIT", 2), route: "home" },
        { name: this.$tc("FISCAL_DATUM", 2), route: "home" },
        { name: this.$tc("REFERRALS", 2), route: "home" },
        { name: this.$tc("ADDRESS", 2), route: "addresses" },
        { name: this.$tc("PACKAGE", 2), route: "packages" },
      ];
    },

    currentTheme() {
      return this.$vuetify.theme.current.dark
        ? this.$t("GO_LIGHT")
        : this.$t("GO_DARK");
    },
  },

  methods: {
    signout() {
      this.logout();
      this.goTo("login");
    },

    changeLocale(locale) {
      setLocale(locale);
      this.$i18n.locale = locale;
    },
  },

  watch: {},

  mounted() {
    this.fetchCurrentUser();
  },
};
</script>
