const themeKey  = 'aHMtbG9jYWwtdGhlbWU='
const localeKey = 'aHMtbG9jYWwtbG9jYWxl'

const authKey = btoa(`auth-${process.env.VUE_APP_TENANT_ID}`)
const tenantKey = btoa(`tower-tenant`)

export function deleteLocalItem (item) {
  window.localStorage.removeItem(item)
}

export function setTheme(theme) {
  window.localStorage.setItem(themeKey, theme)
}

export function getTheme () {
  return window.localStorage.getItem(themeKey) || 'light'
}

export function setLocale (locale) {
  window.localStorage.setItem(localeKey, locale)
}

export function getLocale () {
  return window.localStorage.getItem(localeKey) || 'es-MX'
}

export function setAuth (auth) {
  return window.localStorage.setItem(authKey, btoa(JSON.stringify(auth)))
}

export function getAuth () {
  const val = window.localStorage.getItem(authKey)
  if (val) {
    return JSON.parse(atob(val))
  }

  return {}
}

export function deleteAuth () {
  window.localStorage.removeItem(authKey)
}

export function setTenant (tenant) {
  return window.localStorage.setItem(tenantKey, btoa(JSON.stringify(tenant)))
}

export function getTenant () {
  const val = window.localStorage.getItem(tenantKey)
  if (val) {
    return JSON.parse(atob(val))
  }

  return {}
}

export function deleteTenant () {
  window.localStorage.removeItem(tenantKey)
}
