import moment from 'moment'
import currencyFormatter from 'currency-formatter'

export default function (app) {
  app.config.globalProperties.$filters = {
    currency (value, params = {}) {
      const options = Object.assign({
        code: 'MXN',
        decimalDigits: 2
      }, params)
      return currencyFormatter.format(value, options)
    },

    date (dateTimeStr, format) {
      if (!dateTimeStr) { return }

      switch (format) {
        case 'short':
          return moment(dateTimeStr).format('MMM DD HH:mm')
        default:
          return moment(dateTimeStr).format(format)
      }
    },

    duration (value, showSeconds = false) {
      let secNum = parseInt(value, 10)
      let hours = Math.floor(secNum / 3600)
      let minutes = Math.floor((secNum - (hours * 3600)) / 60)
      let seconds = secNum - (hours * 3600) - (minutes * 60)

      if (hours < 10) { hours = '0' + hours }
      if (minutes < 10) { minutes = '0' + minutes }
      if (seconds < 10) { seconds = '0' + seconds }
      if (showSeconds) {
        return hours + ':' + minutes + ':' + seconds
      } else {
        return hours + ':' + minutes
      }
    }
  }
}
